<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/not-authorized.png" alt="graphic-not-authorized" class="mx-auto mb-4">
            <h1 class="sm:mx-0 mx-4 sm:mb-8 mb-4 text-5xl d-theme-heading-color">Bem vindo ao SIGCFC!</h1>
            <vs-alert :active="!!disable" class="mt-5" icon-pack="feather" icon="icon-info">
              <span>Um novo link de verificação de email foi enviado para o endereço de e-mail registrado em seu cadastro.</span>
            </vs-alert>
            <h1 class="box-success mt-4">Seu email precisa ser verificado <a href="#"><span class="glyphicon glyphicon-home pull-right" aria-hidden="true"></span></a></h1>
              <blockquote>
                  <p><strong>Atenção!</strong></p>
                  <p>Um email foi enviado para o endereço utilizado em seu cadastro com um link que deve ser acessado para ativar sua conta.</p>
                  <p>Por favor verifique seu email, inclusive na caixa de spams.</p>
                  <p>Caso não tenha recebido um email do SIGCFC, clique no botão abaixo abaixo para solicitar o reenvio.</p>
              </blockquote>
            <vs-button class="mt-8" size="large" :disabled="disable" @click="send">Reenviar link de ativação</vs-button>
        </div>
    </div>
</template>

<script>
import axios from '@/axios.js'

export default {
  data () {
    return {
      disable: false,
      urlResendVerification: `${process.env.VUE_APP_API_URL}/email/resend`
    }
  },
  methods: {
    async send () {
      try {
        this.sendButton = true
        const resp = await axios.get(this.urlResendVerification)
        if (resp.status === 200 && resp.data.msg === 'Email verification link sent on your email id') {
          this.disable = true
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
