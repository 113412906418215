var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("@/assets/images/pages/not-authorized.png"),
            alt: "graphic-not-authorized",
          },
        }),
        _c(
          "h1",
          {
            staticClass:
              "sm:mx-0 mx-4 sm:mb-8 mb-4 text-5xl d-theme-heading-color",
          },
          [_vm._v("Bem vindo ao SIGCFC!")]
        ),
        _c(
          "vs-alert",
          {
            staticClass: "mt-5",
            attrs: {
              active: !!_vm.disable,
              "icon-pack": "feather",
              icon: "icon-info",
            },
          },
          [
            _c("span", [
              _vm._v(
                "Um novo link de verificação de email foi enviado para o endereço de e-mail registrado em seu cadastro."
              ),
            ]),
          ]
        ),
        _vm._m(0),
        _vm._m(1),
        _c(
          "vs-button",
          {
            staticClass: "mt-8",
            attrs: { size: "large", disabled: _vm.disable },
            on: { click: _vm.send },
          },
          [_vm._v("Reenviar link de ativação")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "box-success mt-4" }, [
      _vm._v("Seu email precisa ser verificado "),
      _c("a", { attrs: { href: "#" } }, [
        _c("span", {
          staticClass: "glyphicon glyphicon-home pull-right",
          attrs: { "aria-hidden": "true" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("blockquote", [
      _c("p", [_c("strong", [_vm._v("Atenção!")])]),
      _c("p", [
        _vm._v(
          "Um email foi enviado para o endereço utilizado em seu cadastro com um link que deve ser acessado para ativar sua conta."
        ),
      ]),
      _c("p", [
        _vm._v("Por favor verifique seu email, inclusive na caixa de spams."),
      ]),
      _c("p", [
        _vm._v(
          "Caso não tenha recebido um email do SIGCFC, clique no botão abaixo abaixo para solicitar o reenvio."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }